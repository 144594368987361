@font-face {
  font-family: 'SuisseIntl';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/SuisseIntl-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseIntl';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/SuisseIntl-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SuisseIntl';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/SuisseIntl-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Inter-Regular.ttf') format('truetype');
  font-display: swap;
}
